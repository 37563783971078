<template>
  <div class="about">
    <h1>About Us</h1>
    <p>Cash Box Credit Union is a community savings and loan cooperative, where members pool their savings to lend to one another and help run the credit union.</p>
    <p>We formed in 2005 by the merger of several smaller local credit unions. </p>
    <p>You can join Cash Box if you live or work in Tameside and Glossop; this is our ‘common bond’. Cash Box offers a safe place to save your money and somewhere to borrow at low rates of interest.</p>
    <p>We aim to promote financial inclusion by offering secure savings and affordable loan products.</p>
    <p>Cash Box is authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority.</p>
    <p>We currently offer a variety of savings accounts, low interest loans and (by arrangement with your employer) payroll deductions.</p>
    <p>Most members save or repay loans by standing order.</p>
    <p>View Cash Box Credit Union Rule Book by <a href="/misc/cash-box-credit-union-rule-book-2019.pdf" target="_blank">clicking here</a>.</p>
  </div>
</template>

<script>
export default {
  name: 'About',
  metaInfo() {
    return {
      title: 'About | Cash Box Credit Union',
      meta: [
        { name: 'keywords', content: "" },
        { name: 'description', content: "" }
      ],
    };
  }
}
</script>

<style scoped>

</style>
